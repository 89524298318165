import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js'
import { startOfDay, endOfDay, subDays } from 'date-fns';
import Datepicker from "react-tailwindcss-datepicker";
import axios from 'axios';
import Medal from './images/medal.png'
import Medal_1 from './images/medal_1.png'
import Medal_2 from './images/medal_2.png'
import Loading from './isLoading';
function App() {

  const supabaseUrl = "https://nmbgtmovabrjbqxqiljx.supabase.co"
  const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5tYmd0bW92YWJyamJxeHFpbGp4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjc5NjU0NDUsImV4cCI6MjA0MzU0MTQ0NX0.7xdZs5rDpZ6iNI7JWaO5GmzoiY315Nl41llaPsQ_fOo'

  const supabase = createClient(supabaseUrl, supabaseKey)
  const [loading, setLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [selectedSalesChannels, setSelectedSalesChannels] = useState([1, 6, 8])

  const toggleSalesChannel = (channel) => {
    if (selectedSalesChannels.includes(channel)) {
      setSelectedSalesChannels(selectedSalesChannels.filter(c => c !== channel))
    } else {
      setSelectedSalesChannels([...selectedSalesChannels, channel])
    }
  }

  const [selectedDate, setSelectedDate] = useState({
    startDate: null,
    endDate: null
  });

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  useEffect(() => {
    if (selectedDate.startDate && selectedDate.endDate) {
      setStartDate(new Date(startOfDay(selectedDate.startDate)));
      setEndDate(new Date(endOfDay(selectedDate.endDate)));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (startDate && endDate) {
      getMerchants();
    }
  }, [startDate, endDate, selectedSalesChannels]);

  useEffect(() => {
    getMerchants();
    setStartDate(startOfDay(new Date()));
    setEndDate(endOfDay(new Date()));
  }, [])

  const getMerchants = async () => {
    setLoading(true);
    let { data: merchants, error } = await supabase
      .from('merchants')
      .select('*');

    if (error) {
      console.error('Error fetching data:', error);
    } else {
      console.log(new Date(startDate), new Date(endDate));
      const merchantsWithTokens = await Promise.all(
        merchants.map(async (merchant) => {
          let access_token = null;
          let orders = [];
          access_token = await getAccessToken(merchant.client_id, merchant.client_secret, merchant.subdomain);
          if (access_token) {
            let hasNext = true;
            for (let i = 1; hasNext; i++) {
              let resp = await getOrders(access_token, startDate, endDate, i);
              orders = [...orders, ...resp.orders];
              hasNext = resp.hasNext;
            }
          }
          return {
            ...merchant,
            access_token,
            orders
          };
        })
      );
      setMerchants(merchantsWithTokens.map(m => ({ ...m, totalFinalPriceSum: m.orders.reduce((sum, order) => sum + order.totalFinalPrice, 0) })));
      setTotalPrice((merchantsWithTokens.map(m => ({ ...m, totalFinalPriceSum: m.orders.reduce((sum, order) => sum + order.totalFinalPrice, 0) }))).map(m => m.totalFinalPriceSum).reduce((sum, price) => sum + price, 0));
    }
    console.log(merchants);
    setLoading(false);
  };
  const getAccessToken = async (client_id, client_secret, subdomain) => {
    let accesstoken = null;
    try {
      const config = {
        method: 'post',
        url: `https://${subdomain}.myikas.com/api/admin/oauth/token`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          grant_type: 'client_credentials',
          client_id,
          client_secret
        }
      };

      accesstoken = (await axios(config)).data.access_token
    } catch (error) {
      accesstoken = null;
    }
    return accesstoken;
  }
  const getOrders = async (accesstoken, startDate, endDate, page) => {
    let orders = [];
    let hasNext = false;
    try {
      const data = {
        query: `query ListOrder($pagination: PaginationInput, $orderedAt: DateFilterInput) {
        listOrder(pagination: $pagination, orderedAt: $orderedAt) {
          count
          data {
            orderedAt
            totalFinalPrice
            currencyCode
            salesChannel {
              id
              name
              type
            }
            workflowName
            currencyRates {
              code
              originalRate
              rate
            }
          }
          count
          hasNext
          limit
          page
        }
      }`,
        variables: {
          pagination: {
            limit: 200,
            page: page
          },
          orderedAt: {
            gte: new Date(startDate).getTime(),
            lte: new Date(endDate).getTime()
          }
        }
      };

      const config = {
        method: 'post',
        url: 'https://api.myikas.com/api/v1/admin/graphql',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accesstoken}`
        },
        data: JSON.stringify(data)
      };
      let response = (await axios(config)).data.data.listOrder;
      orders = response.data;

      ///order only selectedSalesChannels
      orders = orders.filter(order => {
        if (1 == 1) { console.log("order.salesChannel", order, order.salesChannel) }; return selectedSalesChannels.includes(order.salesChannel.type)
      });
      hasNext = response.hasNext;
      //wait 1 sec
      await new Promise(resolve => setTimeout(resolve, 1000));
    } catch (e) {
    }

    return { orders, hasNext };
  }

  if (loading) return <Loading />;

  return (<>
    <div className=" mx-auto p-6 bg-gray-100 " key="channel.name">
      <div className='flex flex-row items-center justify-between gap-2 mb-3'>
        <div className="text-gray-500 mx-2 font-semibold text-lg">Toplam Ciro:
          <span className='text-gray-900 text-xl ml-2'>
            {(totalPrice || 0).toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })} ₺
          </span>
        </div>
        <div className='flex gap-3'>
          <span onClick={() => { toggleSalesChannel(1) }} className={`cursor-pointer text-blue-600 border-blue-600 hover:border-1 hover:border-blue-400 ${selectedSalesChannels.includes(1) && "border-b border-blue-400"}`}>Web</span>
          <span onClick={() => { toggleSalesChannel(3) }} className={`cursor-pointer text-blue-600 border-blue-600 hover:border-1 hover:border-blue-400 ${selectedSalesChannels.includes(3) && "border-b border-blue-400"}`}>Trendyol</span>
          <span onClick={() => { toggleSalesChannel(6) }} className={`cursor-pointer text-blue-600 border-blue-600 hover:border-1 hover:border-blue-400 ${selectedSalesChannels.includes(6) && "border-b border-blue-400"}`}>Manuel</span>
          <span onClick={() => { toggleSalesChannel(8) }} className={`cursor-pointer text-blue-600 border-blue-600 hover:border-1 hover:border-blue-400 ${selectedSalesChannels.includes(8) && "border-b border-blue-400"}`}>Mobil Uygulama</span>
        </div>
        <div className='w-[20%]'>
          <Datepicker
            startWeekOn="mon"
            value={selectedDate}
            primaryColor={"fuchsia"}
            onChange={newValue => setSelectedDate(newValue)}
            showShortcuts={true}
          />
        </div>

      </div>
      <>
        {startDate && endDate && merchants && (
          <div className="grid grid-cols-1 gap-6 grid-cols-1 lg:grid-cols-3 xl:grid-cols-4">
            {merchants.map(m => ({ ...m, totalFinalPriceSum: m.orders.reduce((sum, order) => sum + order.totalFinalPrice, 0) }))
              .sort((a, b) => b.totalFinalPriceSum - a.totalFinalPriceSum)
              .map((merchant, i) => (
                <div key={merchant.merchant_id} className={`${merchant.client_id == "" ? "bg-gray-500" : "bg-white"}  overflow-hidden rounded-lg  px-4 py-5 shadow sm:p-6 transform transition duration-300 hover:scale-110 cursor-pointer`}>
                  <div className={`flex flex-row truncate text-xl font-bold text-gray-800`}>
                    <span >
                      {i == 0 && <img src={Medal} className='w-[30px] h-[30px]' />}
                      {i == 1 && <img src={Medal_1} className='w-[30px] h-[30px]' />}
                      {i == 2 && <img src={Medal_2} className='w-[30px] h-[30px]' />}</span>
                    {merchant.store_name}</div>
                  <span className="truncate text-[10px] font-medium text-gray-500">{merchant.subdomain}.myikas.com</span>
                  <div className='flex flex-row justify-between items-center '>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                      {(merchant.totalFinalPriceSum || 0).toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })} ₺
                    </dd>
                    <dd className="mt-1 text-xl text-gray-500 font-semibold tracking-tight ">
                      {(merchant.orders?.length || 0)} sipariş
                    </dd>
                  </div>
                </div>
              ))
            }

          </div>
        )}

      </>
    </div>
  </>
  )
}

export default App;


