import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppPage from './App';
const Main = () => {
  const [currentPage, setCurrentPage] = useState('login'); // Sayfa kontrolü için state

  const renderPage = () => {
    if (currentPage === 'login') {
      return <Login setCurrentPage={setCurrentPage} />;
    } else if (currentPage === 'app') {
      return <App setCurrentPage={setCurrentPage} />;
    }
    return <div>Page not found</div>;
  };

  return <div>{renderPage()}</div>;
};


// Login ve App bileşenleri
const Login = ({ setCurrentPage }) => {
  const [otp, setOtp] = useState(Array(4).fill("")); // Array with 6 empty strings
  const inputRefs = useRef([]); // Array of refs for each input field

  const handleKeyDown = (e) => {
    if (
      !/^[0-9]{1}$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Tab" &&
      !e.metaKey
    ) {
      e.preventDefault();
    }

    if (e.key === "Delete" || e.key === "Backspace") {
      const index = inputRefs.current.indexOf(e.target);
      if (index > 0) {
        setOtp((prevOtp) => [
          ...prevOtp.slice(0, index - 1),
          "",
          ...prevOtp.slice(index),
        ]);
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleInput = (e) => {
    const { target } = e;
    const index = inputRefs.current.indexOf(target);
    if (target.value) {
      setOtp((prevOtp) => [
        ...prevOtp.slice(0, index),
        target.value,
        ...prevOtp.slice(index + 1),
      ]);
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    if (!new RegExp(`^[0-9]{${otp.length}}$`).test(text)) {
      return;
    }
    const digits = text.split("");
    setOtp(digits);
  };

  useEffect(() => {
    console.log("otp", otp)
    if (otp.join('').length === 4) {
      otp.join('') === '1345' ? setCurrentPage('app') : alert('Hatalı giriş yaptınız');
    }
  }, [otp]);

  return (
    <div className="w-full h-[100vh] flex justify-center items-center border border-black">
      <div className="flex flex-col text-center">
        <p className='pb-5'>Lütfen Giriş Yapınız</p>
        <form id="otp-form" className="flex gap-2 w-full justify-center items-center">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={handleInput}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onPaste={handlePaste}
              ref={(el) => (inputRefs.current[index] = el)}
              className="shadow-xs flex w-[64px] items-center justify-center rounded-lg border border-stroke bg-white p-2 text-center text-2xl font-medium text-gray-5 outline-none sm:text-4xl dark:border-dark-3 dark:bg-white/5"
            />
          ))}
          {/* You can conditionally render a submit button here based on otp length */}
        </form>
      </div>
    </div>
  );
};

const App = ({ setCurrentPage }) => (
  <AppPage />
);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);
